import { colors } from "../../utils/const";

export const styles = {
  btn: {
    p: 0,
    fontSize: { xs: "10px", md: "12px", lg: "12px" },
    fontWeight: "bold",
    width: { xs: "150px", md: "200px", lg: "300px" },
    height: "40px",
    color: colors.white,
    border: `1px solid ${colors.orange}`,
    backgroundColor: colors.orange,
    borderRadius: 2,
    position: "relative",
    overflow: "hidden",

    "&:hover": {
      border: `1px solid ${colors.orange}`,
      backgroundColor: colors.orange,
      color: colors.white,
    },
    "&:active": {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: `${colors.orange}`,
      zIndex: 0,
      pointerEvents: "none",
      opacity: 1,
    },

    "& span": {
      position: "relative",
      zIndex: 1,
    },
  },
  icon: (theme) => ({
    m: "8px",
    transition: ".5s",
    "&:hover": {
      transform: "scale(1.3)",
    },
  }),
};
